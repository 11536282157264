import React, { useEffect, useRef, useState } from 'react'
import style from './NavBar.module.css'
import logo from '../../../assets/images/logo.png'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import navBtn from '../../../assets/data/navBtn.json'
import { MdClose, MdOutlineAccountCircle, MdOutlineAdminPanelSettings, MdOutlineLogin, MdOutlineMenu, MdOutlineSearch, MdOutlineShoppingBag, MdOutlineShoppingCart } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { getToken } from 'services/operations/userAPI'

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const { token, user, isLogin } = useSelector(state => state.user);

    const [showSidebar, setShowSidebar] = useState(false);
    const [search, setSearch] = useState('');

    const sidebarRef = useRef();

    useOnClickOutside(sidebarRef, () => setShowSidebar(false));

    useEffect(() => {
        setShowSidebar(false);
    }, [location])

    useEffect(() => {
        if (isLogin) {
            getToken(dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (searchParams.get('search')) {
            setSearch(searchParams.get('search'));
        }
    }, [searchParams]);

    const handleSearch = () => {
        if (search.length > 0) {
            navigate(`/search?search=${search}`)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={style.NavBar}>
            <div ref={sidebarRef} className={`${style.sidebar} ${showSidebar ? style.showSidebar : style.hideSidebar}`}>
                <div>
                    <button onClick={() => setShowSidebar(false)} className='secondary-btn'><MdClose /></button>
                    {
                        navBtn.map((btn, index) => <button key={index} onClick={() => navigate(btn.route)} className='secondary-btn'>{btn.name}</button>)
                    }
                </div>
                <div>
                    {user?.userType === 'Admin' && <button onClick={() => navigate('/admin')} className='secondary-btn'>Admin Panel</button>}
                    {token && <button className='secondary-btn' onClick={() => navigate('/orders')}>My Orders</button>}
                    {token && <button className='secondary-btn' onClick={() => navigate('/cart')}>My Cart</button>}
                    {token ? <button className='secondary-btn' onClick={() => navigate('/myaccount')}>My Account</button> : <button className='secondary-btn' onClick={() => navigate('/login')}>Login</button>}
                </div>
            </div>
            <nav className={style.MainArea}>
                <img src={logo} onClick={() => navigate('/')} alt='logo' />
                <div className={style.hide}>
                    {
                        navBtn.map((btn, index) => <button key={index} onClick={() => navigate(btn.route)} className='secondary-btn'>{btn.name}</button>)
                    }
                </div>
                <div>
                    {user?.userType === 'Admin' && <MdOutlineAdminPanelSettings onClick={() => navigate('/admin')} className={style.hide} />}
                    {token && <MdOutlineShoppingBag className={style.hide} onClick={() => navigate('/cart')} />}
                    {token && <MdOutlineShoppingCart className={style.hide} onClick={() => navigate('/orders')} />}
                    {token ? <MdOutlineAccountCircle className={style.hide} onClick={() => navigate('/myaccount')} /> : <MdOutlineLogin onClick={() => navigate('/login')} className={style.hide} />}
                    <MdOutlineMenu className={style.show} onClick={() => setShowSidebar(true)} />
                </div>
            </nav>
            <div className={style.SearchArea}>
                <input type='text' required placeholder='Search any item' value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleKeyDown}/>
                <button className={`border-round-btn ${style.hide}`} onClick={handleSearch}>Search</button>
                <button className={`border-round-btn ${style.show}`} onClick={handleSearch}><MdOutlineSearch /></button>
            </div>
        </div>
    )
}

export default NavBar