import toast from "react-hot-toast"
import { apiConnector } from "services/apiConnector";
import { productEndpoints } from "services/apis";

const { CREATE_PRODUCT, UPDATE_PRODUCT, GET_ALL_PRODUCT, GET_PRODUCT, DELETE_PRODUCT } = productEndpoints

export const createProduct = async (data, setter) => {
    const toastId = toast.loading('Creating Product');
    try {
        const response = await apiConnector('POST', CREATE_PRODUCT, data, { 'Content-Type': 'multipart/form-data' });

        if (response.data.success) {
            toast.dismiss(toastId);
            toast.success(response.data?.message);
            setter(null);
        }
    } catch (err) {
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message);
    }
}

export const getProducts = async (query, setter, setPage) => {
    // const toastId = toast.loading('Fetching all products');
    try {
        const response = await apiConnector('GET', GET_ALL_PRODUCT, null, null, query);

        if (response.data.success) {
            // toast.dismiss(toastId);
            // toast.success(response.data?.message);
            setter(response.data.products);
            if(setPage){
                setPage(response.data.pagination);
            }
        }

    } catch (err) {
        // toast.dismiss(toastId)
        toast.error(err?.response?.data?.message);
    }
}

export const updateProduct = async (data, setter) => {
    const toastId = toast.loading('Updating Product');
    try {

        const response = await apiConnector('POST', UPDATE_PRODUCT, data, { 'Content-Type': 'multipart/form-data' });

        if (response.data.success) {
            toast.dismiss(toastId);
            toast.success(response.data?.message);
            setter(null)
        }
    } catch (err) {
        toast.dismiss(toastId)
        toast.error(err?.response?.data?.message);
    }
}

export const deleteProduct = async (data, setter) => {
    const toastId = toast.loading('Deleting Product');
    try {

        const response = await apiConnector('POST', DELETE_PRODUCT, data);

        if (response.data.success) {
            toast.dismiss(toastId);
            toast.success(response.data?.message);
            setter(null)
        }
    } catch (err) {
        toast.dismiss(toastId)
        toast.error(err?.response?.data?.message);
    }
}

export const getProduct = async (data, setter) => {
    const toastId = toast.loading('Fetching Product');
    try {
        const response = await apiConnector('POST', GET_PRODUCT, data);

        if (response.data.success) {
            toast.dismiss(toastId);
            toast.success(response.data.message);
            setter(response.data.product)
        }
    } catch (err) {
        setter(null)
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message);
    }
}