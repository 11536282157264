import React, { useEffect, useState } from 'react'
import style from './Admin.module.css'
import DetailOrder from './DetailOrder';
import { getOrders } from 'services/operations/orderAPI';

const ManageOrders = ({ setPageType, orderType }) => {
    const [orders, setOrders] = useState(null);
    const [loader, setLoader] = useState(true);
    const [order, setOrder] = useState(null);
    const [trigger, setTrigger] = useState(false)

    useEffect(() => {
        getOrders({ type: orderType }, setOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (trigger) {
            setTrigger(false)
            getOrders({ type: orderType }, setOrders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger])

    useEffect(() => {
        if (orders) {
            setLoader(false);
        }
    }, [orders])

    if (order) {
        return <DetailOrder detail={order} setDetail={setOrder} />
    }

    return (
        <div className={style.ManageProduct}>
            <div>
                {orderType === 'Order' && <h1>Manage Orders</h1>}
                {orderType === 'Refund' && <h1>Manage Refunds</h1>}
                {orderType === 'Replacement' && <h1>Manage Replacements</h1>}
                <div>
                    <button className='border-round-btn' onClick={() => setPageType(null)}>Go Back</button>
                    {
                        loader ? <div className={style.loaderBg}><div className='loader'></div></div> :
                            <div className={style.allProduct}>
                                {orders?.length === 0 ? <div className={style.loaderBg}>{orderType === 'Order' && 'No Orders Found'}{orderType === 'Return' && 'No Refunds Found'}{orderType === 'Replacement' && 'No Replacements Found'}</div> :
                                    orders.map((o, index) => <SingeOrder key={index} viewOrder={setOrder} order={o} setTrigger={setTrigger} />)}
                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

const SingeOrder = ({ order, viewOrder, setTrigger }) => {

    return (
        <div className={style.singleOrder}>
            <div>
                <h2>{order?.address?.name}</h2>
                <h3>Status: {order?.status}</h3>
            </div>
            <div>
                <button className='border-btn' onClick={() => viewOrder(order._id)}>View Order</button>
            </div>
        </div>
    )
}

export default ManageOrders