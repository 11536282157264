import React, { useEffect, useState } from 'react'
import style from './Admin.module.css'
import Product from 'components/core/Admin/Product';
import ManageProducts from 'components/core/Admin/ManageProducts';
import ManageOrders from 'components/core/Admin/ManageOrders';
import { useNavigate } from 'react-router-dom';
import image from 'assets/images/image12.jpg'

const Admin = () => {

    const [pageType, setPageType] = useState(null);
    const [product, setProduct] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (pageType !== 'AddProduct') {
            setProduct(null);
        }
    }, [pageType])

    if (pageType === 'AddProduct') {
        return <Product data={product} setPageType={setPageType} />
    }
    if (pageType === 'ManageProducts') {
        return <ManageProducts setPageType={setPageType} setProduct={setProduct} />
    }
    if (pageType === 'ManageOrders') {
        return <ManageOrders setPageType={setPageType} orderType={'Order'} />
    }
    if (pageType === 'ManageReturns') {
        return <ManageOrders setPageType={setPageType} orderType={'Return'} />
    }

    return (
        <div className={style.Admin} style={{ backgroundImage: `url(${image}` }}>
            <div>
                <button onClick={() => navigate('/')} className='border-round-btn'>Go Home</button>
                <button onClick={() => setPageType('AddProduct')} className='border-round-btn'>Add Product</button>
                <button onClick={() => setPageType('ManageProducts')} className='border-round-btn'>Manage Product</button>
                <button onClick={() => setPageType('ManageOrders')} className='border-round-btn'>Manage Orders</button>
                <button onClick={() => setPageType('ManageReturns')} className='border-round-btn'>Manage Returns</button>
            </div>
        </div>
    )
}

export default Admin