import React from 'react'
import style from './Home.module.css'

const Why = () => {
    return (
        <div className={style.Why}>
            <h3>Why Canes Hub ?</h3>
            <div>
                <h4>Secure and recyclable packing</h4>
                <h4>Free Replacement in case of Damaged</h4>
                <h4>3 Days Money Back Guarentee</h4>
            </div>
        </div>
    )
}

export default Why