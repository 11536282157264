import React, { useEffect, useState } from 'react'
import style from './Order.module.css'
import { getMyOrders } from 'services/operations/orderAPI';
import ProductCart from 'components/common/ProductCart/ProductCart';

function restructure(cart) {
    return cart.map(order => {
        const productNames = order.cart.map(item => item.product.name);
        const productImages = order.cart.flatMap(item => item.product.images);
        const description = `${productNames.join(', ')}`;

        let product = {};
        product.name = order.address.name;
        product.description = description;
        product.images = productImages;
        product.status = order.status;
        product.price = order.totalPrice;
        return { product: product, id: order._id };
    });
}
const Order = () => {

    const [orders, setOrders] = useState(null);

    useEffect(() => {
        getMyOrders(setOrders);
    }, [])

    if (!orders) {
        return <div className='loaderBg'><div className='loader'></div></div>
    }

    return (
        <div className={style.Order}>
            <h3>My Order</h3>
            <div>
                {orders && <ProductCart products={restructure(orders)} type={'order'} />}
            </div>
        </div>
    )
}

export default Order