import React, { useEffect, useState } from 'react'
import style from './Cart.module.css'
import { getMyCart } from 'services/operations/orderAPI';
import { useDispatch, useSelector } from 'react-redux';
import ProductCart from 'components/common/ProductCart/ProductCart';
import { useNavigate } from 'react-router-dom';

const Cart = () => {

    const [products, setProducts] = useState(null);
    const [totalCost, setTotalCost] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.user);

    useEffect(() => {
        getMyCart(setProducts, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Early return if necessary data is missing
        if (!products || !user || !user.cart) return;

        const mergedProducts = products
            .map((product) => {
                const matchingCartItem = user.cart.find(
                    (cartItem) => cartItem.product === product.product._id
                );
                return {
                    ...product,
                    quantity: matchingCartItem ? matchingCartItem.quantity : 0,
                };
            })
            .filter((product) => product.quantity > 0); // Filter out products with quantity 0

        setProducts(mergedProducts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (products) {
            let total = 0;
            let discount = 0;
            products.forEach((item) => {
                total += (item.product.discountPrice * item.quantity);
                discount += ((item.product.price - item.product.discountPrice) * item.quantity);
            })

            setTotalCost(total);
            setTotalDiscount(discount)
        }
    }, [products])


    if (!products) {
        return <div className='loaderBg'><div className='loader'></div></div>
    }

    return (
        <div className={style.Cart}>
            <h3>My Cart</h3>
            <div>
                <ProductCart products={products} type={'cart'} />
                {products.length !== 0 && <div className={style.detail}>
                    <div>
                        <h3>Total Cost:</h3>
                        <h4>₹{totalCost + totalDiscount}</h4>
                    </div>
                    <div>
                        <h3>Total discount:</h3>
                        <h4 style={{ color: 'red' }}>- ₹{totalDiscount}</h4>
                    </div>
                    <div>
                        <h3>Delivery Charges:</h3>
                        <h4>₹40</h4>
                    </div>
                    <div>
                        <h3>Delivery discount:</h3>
                        <h4 style={{ color: 'red' }}>- ₹40</h4>
                    </div>
                    <div>
                        <h3>Final Cost:</h3>
                        <h4>{totalCost}</h4>
                    </div>
                    <button className='border-btn' onClick={() => navigate('/booking')}>Pay ₹{totalCost}</button>
                </div>}
            </div>
        </div>
    )
}

export default Cart