import React from 'react';
import style from './Login.module.css';
import image from 'assets/images/image10.jpg';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signinUser } from 'services/operations/userAPI';

const Login = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSubmit = (data) => {
		signinUser(data, dispatch);
	};

	return (
		<div className={style.Login}>
			<img src={image} alt="Look" />
			<form onSubmit={handleSubmit(onSubmit)}>
				<h2>Login</h2>
				<div>
					<label htmlFor="email">Email</label>
					<input
						type="email"
						id="email"
						{...register('email', {
							required: 'Email is required',
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: 'Invalid email address',
							},
						})}
					/>
					{errors.email && <span>{errors.email.message}</span>}
				</div>
				<div>
					<label htmlFor="password">Password</label>
					<input
						type="password"
						id="password"
						{...register('password', {
							required: 'Password is required',
							minLength: {
								value: 6,
								message: 'Password must be at least 6 characters',
							},
						})}
					/>
					{errors.password && <span>{errors.password.message}</span>}
				</div>
				<button style={{ marginTop: '2rem' }} type="submit" className='border-round-btn'>Login</button>
				<button style={{ marginTop: '1rem', fontSize: '0.85rem' }} className='border-round-btn' onClick={() => navigate('/signup')}>Create Account</button>
				<button style={{ marginTop: '1rem', fontSize: '0.85rem' }} className='border-round-btn' onClick={() => navigate('/reset-password')}>Forget Password</button>
			</form>
		</div>
	);
};

export default Login;