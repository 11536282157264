import React from 'react';
import style from './Home.module.css';
import SimpleImageSlider from "react-simple-image-slider";
import 'react-slideshow-image/dist/styles.css'
import image1 from '../../../assets/images/image1.jpg'
import image2 from '../../../assets/images/image2.jpg'
import image3 from '../../../assets/images/image3.jpg'
import image4 from '../../../assets/images/image4.jpg'
import image5 from '../../../assets/images/image5.jpg'
import image6 from '../../../assets/images/image6.jpg'
import image7 from '../../../assets/images/image7.jpg'
import image8 from '../../../assets/images/image8.jpg'
import image9 from '../../../assets/images/image9.jpg'
// import image10 from '../../../assets/images/image10.jpg's

const SlideShow = () => {

    const slideImages = [
        {
            "url": image1,
            "alt": "image1"
        },
        {
            "url": image2,
            "alt": "image2"
        },
        {
            "url": image3,
            "alt": "image3"
        },
        {
            "url": image4,
            "alt": "image4"
        },
        {
            "url": image5,
            "alt": "image5"
        },
        {
            "url": image6,
            "alt": "image6"
        },
        {
            "url": image7,
            "alt": "image7"
        },
        {
            "url": image8,
            "alt": "image8"
        },
        {
            "url": image9,
            "alt": "image9"
        }
    ]

    return (
        <div className={style.slideShow} >
            <SimpleImageSlider
                width={'100%'}
                height={600}
                images={slideImages}
                showBullets={true}
                showNavs={true}
                navMargin={10}
                navSize={25}
                navStyle={2}
                slideDuration={1.2}
            />
        </div>
    )
}

export default SlideShow