const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

export const authEndpoints = {
    GET_VERIFICATION_LINK: `${BASE_URL}/auth/getVerificationLink`,
    VERIFIED_USER: `${BASE_URL}/auth/verification`,
    GET_RESET_PASSWORD_LINK: `${BASE_URL}/auth/getResetPasswordLink`,
    RESET_PASSWORD: `${BASE_URL}/auth/resetPassword`,
}

export const userEndpoints = {
    SIGN_IN: `${BASE_URL}/user/signin`,
    SIGN_UP: `${BASE_URL}/user/signup`,
    GET_TOKEN: `${BASE_URL}/user/getToken`,
    GET_USER: `${BASE_URL}/user/getUser`,
    UPDATE_USER: `${BASE_URL}/user/updateUser`,
    DELETE_USER: `${BASE_URL}/user/deleteUser`,
    GET_ALL_USERS: `${BASE_URL}/user/getAllUsers`,
    GET_ADDRESS: `${BASE_URL}/user/getAddress`,
    UPDATE_ADDRESS: `${BASE_URL}/user/updateAddress`,
    REMOVE_ADDRESS: `${BASE_URL}/user/removeAddress`,
    ADD_ADDRESS: `${BASE_URL}/user/addAddress`,
}

export const productEndpoints = {
    CREATE_PRODUCT: `${BASE_URL}/products/createProduct`,
    UPDATE_PRODUCT: `${BASE_URL}/products/updateProduct`,
    DELETE_PRODUCT: `${BASE_URL}/products/deleteProduct`,
    GET_ALL_PRODUCT: `${BASE_URL}/products/getAllProduct`,
    GET_PRODUCT: `${BASE_URL}/products/getProduct`,
}

export const orderEndpoints = {
    ADD_TO_CART: `${BASE_URL}/order/addToCart`,
    REMOVE_FROM_CART: `${BASE_URL}/order/removeFromCart`,
    GET_MY_CART: `${BASE_URL}/order/getMyCart`,
    GET_MY_ORDERS: `${BASE_URL}/order/getMyOrders`,
    GET_ORDERS: `${BASE_URL}/order/getOrders`,
    UPDATE_ORDER: `${BASE_URL}/order/updateOrder`,
    CASH_ON_DELIVERY: `${BASE_URL}/order/cashOnDelivery`,
    CAPTURE_PAYMENT: `${BASE_URL}/order/capturePayment`,
    VERIFY_PAYMENT: `${BASE_URL}/order/verifyPayment`,
    CANCEL_ORDER: `${BASE_URL}/order/cancelOrder`,
    RETURN_ORDER: `${BASE_URL}/order/returnOrder`,
    REPLACE_ORDER: `${BASE_URL}/order/replaceOrder`,
}

export const reviewEndpoints = {
    GET_REVIEW: `${BASE_URL}/review/getReview`,
    CREATE_REVIEW: `${BASE_URL}/review/createReview`,
    UPDATE_REVIEW: `${BASE_URL}/review/updateReview`,
    DELETE_REVIEW: `${BASE_URL}/review/deleteReview`,
}