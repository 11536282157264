import { Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/common/NavBar/NavBar';
import Home from './pages/Home/Home';
import Footer from 'components/common/Footer/Footer';
import Search from 'pages/Search/Search';
import ProtectedRoute from 'routes/protectedRoute';
import AdminRoute from 'routes/adminRoute';
import Admin from 'pages/Admin/Admin';
import Login from 'pages/Login/Login';
import OpenRoute from 'routes/openRoute';
import Signup from 'pages/Signup/Signup';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import ContactUs from 'pages/Utils/ContactUs';
import AboutUs from 'pages/Utils/AboutUs';
import Privacy from 'pages/Utils/Privacy';
import Refund from 'pages/Utils/Refund';
import Order from 'pages/Order/Order';
import Cart from 'pages/Cart/Cart';
import Profile from 'pages/Profile/Profile';
import Product from 'pages/Product/Product';
import Booking from 'pages/Booking/Booking';
import OrderDetail from 'pages/Order/OrderDetail';
import Return from 'pages/Return/Return';
import Review from 'pages/Review/Review';
import ResetPasswordRoute from 'pages/ResetPassword/ResetPasswordRoute';
import Error from 'pages/Utils/Error';
import Verification from 'pages/Verification/Verification';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { verifyAccount } from 'services/operations/authAPI';
import { getUser } from 'services/operations/userAPI';

function App() {

    const { user } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const verify = async () => {
        await verifyAccount({ token: sessionStorage.getItem('verifyToken') });
        sessionStorage.removeItem('verifyToken');
        await getUser(dispatch);
    }

    useEffect(() => {
        if (user && sessionStorage.getItem('verifyToken') && !user.isVerified) {
            verify();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <div className="App">
            <NavBar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/search' element={<Search />} />
                <Route path='/admin' element={<AdminRoute><Admin /></AdminRoute>} />
                <Route path='/login' element={<OpenRoute><Login /></OpenRoute>} />
                <Route path='/signup' element={<OpenRoute><Signup /></OpenRoute>} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/reset-password/:id' element={<ResetPasswordRoute />} />
                <Route path='/contactUs' element={<ContactUs />} />
                <Route path='/aboutUs' element={<AboutUs />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/refund' element={<Refund />} />
                <Route path='/orders' element={<ProtectedRoute><Order /></ProtectedRoute>} />
                <Route path='/orders/:id' element={<ProtectedRoute><OrderDetail /></ProtectedRoute>} />
                <Route path='/return/:id' element={<ProtectedRoute><Return /></ProtectedRoute>} />
                <Route path='/verify/:token' element={<Verification />} />
                <Route path='/product/:id' element={<Product />} />
                <Route path='/reviews/:id' element={<Review />} />
                <Route path='/cart' element={<ProtectedRoute><Cart /></ProtectedRoute>} />
                <Route path='/myaccount' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path='/booking' element={<ProtectedRoute><Booking /></ProtectedRoute>} />
                <Route path='*' element={<Error />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
