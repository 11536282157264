import React from 'react';
import style from './Signup.module.css';
import { useForm } from 'react-hook-form';
import image from 'assets/images/image10.jpg'
import { useNavigate } from 'react-router-dom';
import { signupUser } from 'services/operations/userAPI';

const Signup = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const navigate = useNavigate();

    const password = watch('password');

    const onSubmit = (data) => {
        // Handle form submission logic here
        signupUser(data, navigate);
    };

    return (
        <div className={style.Signup}>
            <img src={image} alt='look' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Sign Up</h2>
                <div>
                    <label htmlFor="fullname">Full Name</label>
                    <input
                        type="text"
                        id="fullname"
                        {...register('fullname', { required: 'Full Name is required' })}
                    />
                    {errors.fullname && <span>{errors.fullname.message}</span>}
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Invalid email address',
                            },
                        })}
                    />
                    {errors.email && <span>{errors.email.message}</span>}
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Password must be at least 6 characters',
                            },
                        })}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        {...register('confirmPassword', {
                            required: 'Confirm Password is required',
                            validate: (value) =>
                                value === password || 'Passwords do not match',
                        })}
                    />
                    {errors.confirmPassword && (
                        <span>{errors.confirmPassword.message}</span>
                    )}
                </div>
                <button type="submit" className='border-round-btn' style={{marginTop: '2rem'}}>Sign Up</button>
				<button style={{ marginTop: '1rem', fontSize: '0.85rem' }} className='border-round-btn' onClick={() => navigate('/login')}>Login</button>
            </form>
        </div>
    );
};

export default Signup;