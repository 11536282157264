import React from 'react'
import style from './ResetPassword.module.css'
import image from 'assets/images/image10.jpg';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from 'services/operations/authAPI';
import { useDispatch } from 'react-redux';

const ResetPasswordRoute = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const { id } = useParams();

    const password = watch('password');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        resetPassword({ token: id, password: data.password }, dispatch, navigate);
    };
    return (
        <div className={style.ResetPassword}>
            <img src={image} alt="Look" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Reset password</h2>
                <div>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Password must be at least 6 characters',
                            },
                        })}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        {...register('confirmPassword', {
                            required: 'Confirm Password is required',
                            validate: (value) =>
                                value === password || 'Passwords do not match',
                        })}
                    />
                    {errors.confirmPassword && (
                        <span>{errors.confirmPassword.message}</span>
                    )}
                </div>

                <button style={{ marginTop: '2rem' }} type="submit" className='border-round-btn'>Reset Password</button>
                <button style={{ marginTop: '1rem', fontSize: '0.85rem' }} className='border-round-btn' onClick={() => navigate('/login')}>Login</button>
            </form>
        </div>
    )
}

export default ResetPasswordRoute