import React from 'react'
import style from './ProductCard.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductCard = ({ data }) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className={style.ProductCard} style={{ width: location.pathname === '/search' ? 'auto' : '180px' }} onClick={() => navigate(`/product/${data._id}`)}>
            <img src={data?.images[0]} alt='product' />
            <h3>{data?.name}</h3>
            <h4>Rs. {data?.discountPrice} {((data.price - data.discountPrice) / data.price * 100) > 1 && <span>({Math.floor((data.price - data.discountPrice) / data.price * 100)}% Off)</span>}</h4>
            <button className='border-round-btn'>Buy Now</button>
        </div>
    )
}

export default ProductCard