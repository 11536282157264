import React from 'react'
import style from './Utils.module.css'

const ContactUs = () => {
    return (
        <div className={style.Utils}>
            <div>
                <h1>Contact Us</h1>
                <ul>
                    <li>Email: <a href="mailto:sonunewcane2023@gmail.com" target='_blank' rel='noreferrer'>sonunewcane2023@gmail.com</a></li>
                    <li>Phone: <a href="tel://+918076096927" target='_blank' rel='noreferrer'> +918076096927</a> (Hours: Monday-Friday, 9:00 AM to 5:00 PM PST)</li>
                    <li>Instagram: <a href="https://www.instagram.com/canes_hub2024" target='_blank' rel='noreferrer'>https://www.instagram.com/canes_hub2024</a></li>
                </ul>
            </div>

        </div>
    )
}

export default ContactUs