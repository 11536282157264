import React, { useEffect, useState } from 'react'
import style from './Product.module.css'
import ReactImageGallery from 'react-image-gallery'
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import { useNavigate } from 'react-router-dom'
import { addToCart, removeFromCart } from 'services/operations/orderAPI'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

const convertToGalleryImages = (images) => {
    return images.map((image) => ({
        original: image,
        thumbnail: image,
        thumbnailHeight: '50px',
        thumbnailWidth: '100px',
        originalHeight: '500px',
        originalWidth: '400px',
        thumbnailClass: style.thumbnailClass,
        originalClass: style.originalClass
    }));
};

const countItems = (cart, id) => {
    const item = cart.filter((item => item.product === id))

    if (item.length !== 0) {
        return item[0].quantity;
    }
    return 0;
}

const ProductDetail = ({ product }) => {
    const { user } = useSelector(state => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [checkCart, setCheckCart] = useState(0)
    const [hold, setHold] = useState(false);

    const cart = async (val) => {
        if (!user) {
            navigate('/login')
            return;
        }
        if (hold) {
            toast('Wait');
            return;
        }
        if (val) {
            if (checkCart !== 0) {
                navigate('/cart');
            } else {
                setHold(true)
                await addToCart({ productId: product._id }, dispatch, user);
                setHold(false)
                navigate('/cart');
            }
        }
        else {
            if (checkCart !== 0) {
                setHold(true)
                await removeFromCart({ productId: product._id }, dispatch, user);
                setHold(false)
            } else {
                setHold(true)
                await addToCart({ productId: product._id }, dispatch, user);
                setHold(false)
            }
        }
    }

    useEffect(() => {
        if (user) {
            setCheckCart(countItems(user.cart, product._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <div className={style.ProductDetail}>
            <div>
                <ReactImageGallery items={convertToGalleryImages(product.images)} thumbnailPosition='bottom' showIndex={true} showPlayButton={false} showNav={false} />
                <div>
                    <button className='primary-round-btn' onClick={() => cart('buy')}>{checkCart !== 0 ? `View Cart` : 'Buy Now'}</button>
                    <button className='border-round-btn' onClick={() => cart()}>{checkCart !== 0 ? `Remove From Cart (${checkCart})` : 'Add to Cart'}</button>
                </div>
            </div>
            <div>
                <h2>{product.name}</h2>
                <h4>{product.description}</h4>
                <div className={style.priceTag}>
                    <h3>₹ {product.discountPrice}</h3>
                    <span>{product.price}</span>
                </div>
                <h3>Detailed View</h3>
                <ul>
                    {product.details.map((detail, index) => (<li key={index}>{detail}</li>))}
                </ul>
                <h3>Offers</h3>
                <ul>
                    <li>3 Days Replacement Guarantee</li>
                    {product.cashOnDelivery === "On" && <li>Cash On Delivery Availble</li>}
                </ul>
            </div>
        </div>
    )
}

export default ProductDetail