import React, { useEffect, useState } from 'react'
import style from './Admin.module.css'
import { getProducts } from 'services/operations/productAPI';

const ManageProducts = ({ setPageType, setProduct }) => {

    const [products, setProducts] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getProducts({ limit: 9999, sortBy: 'createdAt', availibility: false }, setProducts);
    }, [])

    useEffect(() => {
        if (products.length > 0) {
            setLoader(false);
        }
    }, [products])

    return (
        <div className={style.ManageProduct}>
            <div>
                <h1>Manage Products</h1>
                <div>
                    <button className='border-round-btn' onClick={() => setPageType(null)}>Go Back</button>
                    {
                        loader ? <div className={style.loaderBg}><div className='loader'></div></div> :
                            <div className={style.allProduct}>
                                {products?.length === 0 ? <div className={style.loaderBg}>No Product Found</div> :
                                    products.map((p, index) => <SingeNews key={index} setEditProduct={() => { setProduct(p); setPageType('AddProduct') }} product={p} />)}
                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

const SingeNews = ({ product, setEditProduct }) => {
    return (
        <div onClick={() => setEditProduct(product)} className={style.singleProduct}>
            <div>
                <h2>{product.name}</h2>
                <h3>Stock: {product.stock}</h3>
            </div>
            <div>
                <h4>Category: {product?.category}  </h4>
                <h4>|</h4>
                <h4>Price: {product?.price}</h4>
            </div>
        </div>
    )
}

export default ManageProducts