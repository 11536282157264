import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import style from './Admin.module.css';
import { MdClose } from 'react-icons/md';
import { createProduct, deleteProduct, updateProduct } from 'services/operations/productAPI';

const Product = ({ data, setPageType }) => {
    const [product] = useState(data || {});
    const [details, setDetails] = useState(data?.details || []);
    const [detail, setDetail] = useState('');
    const [images, setImages] = useState(data?.images || []);
    const [previewImages, setPreviewImages] = useState(data?.images || []);

    const imageRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: product,
    });

    useEffect(() => {
        // Set the form values when the component mounts or the data prop changes
        setValue('name', product.name || '');
        setValue('price', parseInt(product.price) || 0);
        setValue('discountPrice', parseInt(product.discountPrice) || 0);
        setValue('description', product.description || '');
        setValue('stock', parseInt(product.stock) || 0);
        setValue('category', product.category || '');
        setValue('location', product.location || 'Any');
        setValue('cashOnDelivery', product.cashOnDelivery || 'Off');
    }, [product, setValue]);

    const onSubmit = (formData) => {
        // Handle form submission
        formData.images = images;
        formData.details = details;

        if (data) {
            updateProduct(formData, setPageType);
        } else {
            createProduct(formData, setPageType);
        }
    };

    const addDetails = () => {
        const value = detail;
        if (value.length !== 0) {
            const temp = details;
            temp.push(value)
            setDetails(temp);
        }
        setDetail('');
    }
    const removeDetails = (index) => {
        const temp = details.filter((_, i) => i !== index);
        setDetails(temp);
        setDetail('');
    };
    const addImage = (file) => {
        if (file) {
            setImages((prev) => [...prev, file]);
        }
    }
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            addImage(file);
            setPreviewImages((prev) => [...prev, reader.result]);
        };
    };
    const handleImage = (event) => {
        const files = event.target.files;
        if (files) {
            Array.from(files).forEach((file) => {
                previewFile(file);
            });
        }
    };
    const removeImage = (index) => {
        if (index >= 0) {
            let temp = previewImages.filter((_, i) => i !== index);
            setPreviewImages(temp);

            temp = images.filter((_, i) => i !== index);
            setImages(temp);
        }
    }

    const deleteProductHandler = (e)=>{
        e.preventDefault();
        if(data){
            deleteProduct({id: data._id}, setPageType);
        }
    }

    return (
        <div className={style.Product}>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <button className='border-round-btn' onClick={() => { setPageType(null) }}>Go Back</button>
                    {data && <button type='button' className='border-round-btn' onClick={deleteProductHandler}>Delete Product</button>}

                    <div className={style.imageSection}>
                        <input ref={imageRef} onChange={handleImage} type='file' accept='image/*' multiple />
                        {images.length === 0 && <div onClick={() => imageRef.current.click()} className={style.tempImage}>
                            <h2>Add atleast one image</h2>
                        </div>}
                        <div>
                            {previewImages.map((image, index) => (<div key={index} className={style.imageContainer}><MdClose onClick={() => removeImage(index)} /><img height={'300px'} src={image} alt='productImage' /></div>))}
                        </div>
                        {images.length !== 0 && <button onClick={() => imageRef.current.click()} type='button' className='border-round-btn'>Add Image</button>}
                    </div>

                    <div>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            {...register('name', { required: true, trim: true })}
                        />
                        {errors.name && <span>This field is required</span>}
                    </div>

                    <div>
                        <label htmlFor="price">Price</label>
                        <input
                            type="text"
                            id="price"
                            {...register('price', {
                                required: true,
                                min: 0,
                                pattern: /^\d+$/,
                                validate: (value) => !isNaN(value) || 'Please enter a valid number',
                            })}
                        />
                        {errors.price && (
                            <span>This field is required and must be greater than or equal to 0</span>
                        )}
                    </div>

                    <div>
                        <label htmlFor="discountPrice">Discount Price</label>
                        <input
                            type="text"
                            id="discountPrice"
                            {...register('discountPrice', {
                                required: true,
                                min: 0,
                                pattern: /^\d+$/,
                                validate: (value) => !isNaN(value) || 'Please enter a valid number',
                            })}
                        />
                        {errors.discountPrice && (
                            <span>This field is required and must be greater than or equal to 0</span>
                        )}
                    </div>

                    <div>
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            {...register('description', { required: true })}
                        ></textarea>
                        {errors.description && <span>This field is required</span>}
                    </div>

                    <div>
                        <label htmlFor="stock">Stock</label>
                        <input
                            type="text"
                            id="stock"
                            {...register('stock', {
                                required: true,
                                min: 0,
                                pattern: /^\d+$/,
                                validate: (value) => !isNaN(value) || 'Please enter a valid number',
                            })}
                        />
                        {errors.stock && (
                            <span>This field is required and must be greater than or equal to 0</span>
                        )}
                    </div>

                    <div className={style.details}>
                        <label htmlFor="details">Details</label>
                        <div>
                            {
                                details.map((detail, index) => (
                                    <div key={index}>
                                        <p>{detail}</p>
                                        <button className='border-round-btn' type='button' onClick={() => removeDetails(index)}><MdClose /></button>
                                    </div>
                                ))
                            }
                        </div>
                        <input
                            type="text"
                            id="details"
                            value={detail}
                            onChange={(e) => setDetail(e.target.value)}
                        />
                        {errors.details && <span>Atleast one detail is required</span>}
                        <button className='border-round-btn' onClick={addDetails} type='button'>Add Detail</button>
                    </div>

                    <div>
                        <label htmlFor="category">Category</label>
                        <select id="category" {...register('category', { required: true })}>
                            <option value="">Select a category</option>
                            <option value="Plants">Plants</option>
                            <option value="Seeds">Seeds</option>
                            <option value="Pots">Pots</option>
                            <option value="Care">Care</option>
                            <option value="Furniture">Furniture</option>
                            <option value="Fabric">Fabric</option>
                            <option value="Home & Kitchen">Home & Kitchen</option>
                            <option value="Garden">Garden</option>
                            <option value="Stationary">Stationary</option>
                            <option value="Electronic accessories">Electronic accessories</option>
                        </select>
                        {errors.category && <span>This field is required</span>}
                    </div>

                    <div>
                        <label htmlFor="location">Location</label>
                        <select id="location" {...register('location', { required: true })}>
                            <option value="">Select a location</option>
                            <option value="Any">Any</option>
                            <option value="Indoor">Indoor</option>
                            <option value="Outdoor">Outdoor</option>
                        </select>
                        {errors.location && <span>This field is required</span>}
                    </div>

                    <div>
                        <label htmlFor="cashOnDelivery">Cash on Delivery</label>
                        <select
                            id="cashOnDelivery"
                            {...register('cashOnDelivery', { required: true })}
                        >
                            <option value="Off">Off</option>
                            <option value="On">On</option>
                        </select>
                        {errors.cashOnDelivery && <span>This field is required</span>}
                    </div>

                    <button type="submit" className='border-round-btn'>{data ? 'Update Product' : 'Add Product'}</button>
                </form>
            </div>
        </div>
    );
};

export default Product;