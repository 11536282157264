import React from 'react'
import style from './ProductCart.module.css'
import SingleProduct from './SingleProduct'
import { useNavigate } from 'react-router-dom'

const ProductCart = ({ products, type }) => {
    const navigate = useNavigate();
    return (
        <div className={style.ProductCart}>
            {
                products.length !== 0 ? products.map((product, index) => (
                    <SingleProduct key={index} product={product} type={type} />
                )) : type === 'cart' ? <div className={style.empty}><h2>Your Cart is Empty,<br /> Shop Now</h2><button className='border-round-btn' onClick={() => navigate('/')}>Shop Now</button></div> : <div className={style.empty}><h2>You haven't order anything yet,<br /> Order Now</h2><button className='border-round-btn' onClick={() => navigate('/')}>Shop Now</button></div>
            }
        </div>
    )
}

export default ProductCart