import React from 'react'
import style from './Footer.module.css'
import footer from 'assets/data/footer.json'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className={style.Footer}>
            <div className={style.top}>
                <div>
                    <div>
                        <h3>{footer[0].title}</h3>
                        <ul>
                            {footer[0].links.map((subLink, index) => (
                                <li key={index} onClick={() => navigate(subLink.path)}>{subLink.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3>{footer[1].title}</h3>
                        <ul>
                            {footer[1].links.map((subLink, index) => (
                                <li key={index} ><a href={subLink.path}>{subLink.name}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <h3>{footer[2].title}</h3>
                        <ul>
                            {footer[2].links.map((subLink, index) => (
                                <li key={index} onClick={() => navigate(subLink.path)}>{subLink.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3>{footer[3].title}</h3>
                        <ul>
                            {footer[3].links.map((subLink, index) => (
                                <li key={index} ><a href={subLink.path}>{subLink.name}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={style.down}>
                <p>© 2024, Canes Hub. All rights reserved.</p>
                <p>Best in work from last 10+ years.</p>
            </div>
        </div>
    )
}

export default Footer