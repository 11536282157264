import React from 'react'
import style from './Home.module.css'
import bestSelling from '../../../assets/images/image5.jpg'
import plants from '../../../assets/images/image10.jpg'
import pots from '../../../assets/images/image13.jpg'
import fertilizers from '../../../assets/images/image11.jpg'
import seeds from '../../../assets/images/image7.jpg'
import garden from '../../../assets/images/Garden.jpg'
import home from '../../../assets/images/HomeKitchen.jpg'
import furniture from '../../../assets/images/Furniture.jpg'
import fabric from '../../../assets/images/Fabric.jpg'
import electric from '../../../assets/images/Electric.jpg'
import { useNavigate } from 'react-router-dom'

const category = [
    {
        "name": "Best Selling",
        "route": "/search?sortOrder=desc&sortBy=purchased",
        "image": bestSelling
    },
    {
        "name": "Plants",
        "route": "/search?category=Plants",
        "image": plants
    },
    {
        "name": "Pots",
        "route": "/search?category=Pots",
        "image": pots
    },
    {
        "name": "Fertilizers",
        "route": "/search?category=Care",
        "image": fertilizers
    },
    {
        "name": "Seeds",
        "route": "/search?category=Seeds",
        "image": seeds
    },
    {
        "name": "Furniture",
        "route": "/search?category=Furniture",
        "image": furniture
    },
    {
        "name": "Fabric",
        "route": "/search?category=Fabric",
        "image": fabric
    },
    {
        "name": "Home & Kitchen",
        "route": "/search?category=Home%20%26%20Kitchen",
        "image": home
    },
    {
        "name": "Garden",
        "route": "/search?category=Garden",
        "image": garden
    },
    {
        "name": "Electronic accessories",
        "route": "/search?category=Electronic%20accessories",
        "image": electric
    }
]

const Category = () => {
    const navigate = useNavigate();
    return (
        <div className={style.category}>
            {
                category.map((cat, index) => (
                    <div key={index} onClick={() => navigate(cat.route)}>
                        <img src={cat.image} alt={cat.name} />
                        <p>{cat.name}</p>
                    </div>
                ))
            }
        </div>
    )
}

export default Category