import React from 'react'
import style from './ResetPassword.module.css'
import image from 'assets/images/image10.jpg';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getResetPasswordLink } from 'services/operations/authAPI';

const ResetPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
        // Handle form submission logic here
        getResetPasswordLink({ email: data.email });
    };
    return (
        <div className={style.ResetPassword}>
            <img src={image} alt="Look" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Reset password</h2>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Invalid email address',
                            },
                        })}
                    />
                    {errors.email && <span>{errors.email.message}</span>}
                </div>

                <button style={{ marginTop: '2rem' }} type="submit" className='border-round-btn'>Reset Password</button>
                <button style={{ marginTop: '1rem', fontSize: '0.85rem' }} className='border-round-btn' onClick={() => navigate('/login')}>Login</button>
            </form>
        </div>
    )
}

export default ResetPassword