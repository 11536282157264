import React, { useState } from 'react'
import style from './ProductCart.module.css'
import { MdAdd, MdRemove } from 'react-icons/md'
import { addToCart, removeFromCart } from 'services/operations/orderAPI'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { setReturn } from 'slices/userSlice'

const SingleProduct = ({ product, type }) => {

    const { user, returnProducts } = useSelector(state => state.user);

    const [hold, setHold] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addMore = async() => {
        if(hold){
            toast('Wait')
            return;
        }
        if (type === 'cart') {
            if (product.quantity < product.product.stock) {
                setHold(true);
                await addToCart({ productId: product.product._id }, dispatch, user);
                setHold(false);
            } else {
                toast.error('Out of Stock');
            }
        }
        if (type === 'return') {
            if (product.currQty < product.quantity) {
                const updatedCart = returnProducts.cart.map(item =>
                    item._id === product._id
                        ? { ...item, currQty: item.currQty + 1 }
                        : item
                );

                const updatedReturnProduct = {
                    ...returnProducts,
                    cart: updatedCart,
                };
                
                dispatch(setReturn(updatedReturnProduct));
            } else {
                toast.error('Maximum selected');
            }
        }
    }

    const remove = async() => {
        if(hold){
            toast('Wait');
            return;
        }
        if (type === 'cart') {
            if (product.quantity > 0) {
                setHold(true);
                await removeFromCart({ productId: product.product._id }, dispatch, user);
                setHold(false);
            } else {
                toast.error('Product is not in cart');
            }
        }
        if (type === 'return') {
            if (product.currQty > 0) {
                const updatedCart = returnProducts.cart.map(item =>
                    item._id === product._id
                        ? { ...item, currQty: item.currQty - 1 }
                        : item
                );

                const updatedReturnProduct = {
                    ...returnProducts,
                    cart: updatedCart,
                };
                
                dispatch(setReturn(updatedReturnProduct));
            } else {
                toast.error('Minimum selected');
            }
        }
    }

    const handleClick = () => {
        if (type === 'order') {
            navigate(`/orders/${product.id}`)
        }
    }

    return (
        <div className={style.SingleProduct} style={{ cursor: type === 'order' ? 'pointer' : 'default' }} onClick={handleClick}>
            <img src={product?.product?.images[0]} alt='product' />
            <div>
                <h2>{product.product.name}</h2>
                <h3>{product.product.description}</h3>
            </div>
            {type === 'cart' && <div>
                <h2>Price: ₹{product.product.discountPrice * product.quantity} <span>₹{(product.product.price - product.product.discountPrice) * product.quantity}</span></h2>
                <div className={style.buttons}>
                    <button className='border-round-btn' onClick={remove}><MdRemove /></button>
                    <div className='primary-round-btn'>{product.quantity}</div>
                    <button className='border-round-btn' onClick={addMore}><MdAdd /></button>
                </div>
            </div>}
            {type === 'order' && <div>
                <h2>Price: ₹{product.product.price}</h2>
                <h2>Status: {product.product.status}</h2>
            </div>}
            {type === 'return' && <div>
                <h2>Refund: ₹{product.product.discountPrice * product.currQty} </h2>
                <div className={style.buttons}>
                    <button className='border-round-btn' onClick={remove}><MdRemove /></button>
                    <div className='primary-round-btn'>{product.currQty}</div>
                    <button className='border-round-btn' onClick={addMore}><MdAdd /></button>
                </div>
            </div>}
        </div>
    )
}

export default SingleProduct